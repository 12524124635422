import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { ClusterOutlined, EditOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import AddUser from 'pages/admin/addSystemUser';
import ResetAdminPassword from 'pages/admin/resetAdminPassword';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { login } from 'store/reducers/auth';
import utils from 'utils/utils';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const currentUrl = window.location.origin;
  const filteredApps = user.apps && user.apps.filter(app => app.appUrl !== currentUrl);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const onSave = (payload) => {
    const token = { session: payload.sessionToken, refresh: payload.refreshToken };
    dispatch(login({ user: payload.user, token }));
    setOpenResetPasswordDialog(false);
    setOpenEditProfileDialog(false);
  };

  const handleOrderKeyClick = (appUrl) => {
    const app2Window = window.open(appUrl, "_blank");
    if (app2Window) {
      setTimeout(() => {
        const token = utils.getTokensFromStorage();
        if (token && token.refresh) {
          app2Window.postMessage({ refreshToken: token.refresh }, "*");
        }
      }, 1000);
    }
  };

  return (
    <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {filteredApps && filteredApps.map((app) => (
        <ListItemButton
          key={app.appUrl}
          selected={selectedIndex === app.appUrl}
          onClick={(e) => {
            setSelectedIndex(app.appUrl);
            handleOrderKeyClick(app.appUrl);
          }}
        >
          <ListItemIcon>
            <ClusterOutlined />
          </ListItemIcon>
          <ListItemText primary={app.appName} />
        </ListItemButton>
      ))}
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(e) => {
          handleListItemClick(e, 1);
          e.stopPropagation();
          setOpenEditProfileDialog(true);
        }}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit Profile' />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(e) => {
          handleListItemClick(e, 2);
          e.stopPropagation();
          setOpenResetPasswordDialog(true);
        }}
      >
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary='Reset Password' />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 3} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItemButton>
      {openResetPasswordDialog && (
        <ResetAdminPassword onCancel={() => setOpenResetPasswordDialog(false)} systemUser={user} onSave={onSave} />
      )}
      {openEditProfileDialog && <AddUser onCancel={() => setOpenEditProfileDialog(false)} user={user} onSave={onSave} page='editProfile' />}
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
