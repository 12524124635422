// third-party
import { FormattedMessage } from 'react-intl';

// assets

import { DashboardCustomizeOutlined } from '@mui/icons-material';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import enums from 'utils/enums';
// icons
const icons = {
  DashboardCustomizeOutlined,
  AnalyticsOutlinedIcon
};

// ==============================|| MENU ITEMS - MAIN ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  title: <FormattedMessage id='dashboard' />,
  roleTags: [enums.userRole.SuperAdmin],
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id='dashboard' />,
      subTitle: 'Dashboard',
      type: 'item',
      url: 'dashboard/reports',
      icon: icons.DashboardCustomizeOutlined,
    },
    {
      id: 'analytics',
      title: <FormattedMessage id='analytics' />,
      subTitle: 'Analytics',
      type: 'item',
      url: 'dashboard/analytics',
      icon: icons.AnalyticsOutlinedIcon,
    },
  ]
};

export default dashboard;
